<template>
  <div class="fan-group-report" v-if="firstInitFinished">
    <apply :report="report" :auth="auth" :confirm="confirm" :close="close" @reset="reset"/>
    <van-cell-group inset :border="false" v-if="applicationDetailValue.process">
      <div style="margin-left: 0.35rem;font-size: 17px;font-weight: 500;margin-top: 0.35rem;margin-bottom: 0.25rem">
        流程
      </div>
      <!--      {{ report }}-->
      <process :application-value="applicationDetailValue"/>
    </van-cell-group>
  </div>
</template>

<script>
import Apply from "@/components/Apply/index";
import Process from "@/components/Apply/Process";
import {saveFanGroupReport, queryFanGroupReport, saveApprovalDetail} from "@/api/hoss";

export default {
  components: {Apply, Process},
  data() {
    return {
      report: {},
      auth: true,
      operates: [],
      applicationDetailValue: {},
      firstInitFinished: false,
      isRecommit: false,
    };
  },
  async created() {
    const {id = "", milepostId = "", execDate = "", clientId = ""} = this.$route.query;
    const {value} = await queryFanGroupReport(id, milepostId);
    this.report = {
      reportTime: execDate || +new Date(),
      detail: "",
      state: 0,
      milepostId,
      clientId,
      ...value,
      ...value.resource,
    };
    // this.operates = value.history;
    this.applicationDetailValue = value;
    this.auth = value.authToApprove ?? false;
    this.firstInitFinished = true;
  },
  methods: {
    async confirm(report) {
      if (this.applicationDetailValue.process && this.auth) {
        await saveApprovalDetail(report);
      } else {
        await saveFanGroupReport(report);
      }
      this.$router.back();
    },
    close() {
      this.$router.back();
    },
    reset() {
      // 重新提交
      console.log("重新提交")
      // milepostId
      var milepostId = this.report.milepostId;
      if (!milepostId) {
        milepostId = this.report.resource.milepostId
      }
      if (milepostId) {
        this.isRecommit = true;
        // this.auth = false;
        // 重置report对象
        this.report = {
          reportTime: this.report.execDate || +new Date(),
          detail: this.report.detail,
          state: 0,
          milepostId,
          clientId: this.report.clientId,
        };
        this.applicationDetailValue = {};
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fan-group-report {
  padding-bottom: 44px;
}

.van-cell-group {
  margin: 10px;
  border-radius: 10px;
}
</style>
